import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Compiler, COMPILER_OPTIONS, CompilerFactory } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { HttpClientModule } from '@angular/common/http';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { FormsModule } from '@angular/forms';

// import the JIT compiler
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

// import { PubNubAngular } from 'pubnub-angular2';

import { environment } from '../environments/environment';
import { VtbComponentsModule } from '@sitespirit/vtb-component-library';

import { AppComponent } from './app.component';
import { MyTemplateComponent } from './pages/mytemplate/mytemplate.component';

import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    MyTemplateComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([{
      path: '',
      component: MyTemplateComponent,
      pathMatch: 'full',
      resolve: {
      }
    }]),
    VtbComponentsModule.forRoot(environment),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDbQRG8PPZbPN0EUsOEm-Lbj0sYN4Ho_VI'
    }),
    AgmDirectionModule,
    InViewportModule
  ],
  providers: [
    // aPubNubAngular,

    // providers for the JIT compiler
    {provide: COMPILER_OPTIONS, useValue: {}, multi: true},
    {provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS]},
    {provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory]}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
