import {
  OnInit,
  Compiler,
  Injector,
  NgModuleRef,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  Component,
  NgModule,
  ChangeDetectorRef,
  ApplicationRef,
  ViewChildren,
  NgZone
} from '@angular/core';

import { AgmCoreModule, AgmMap } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { HttpClient } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
//import { PubNubAngular } from 'pubnub-angular2';
import { VtbComponentsModule } from '@sitespirit/vtb-component-library';
import { environment } from '../../../environments/environment';
import { PricesService } from 'src/app/services/prices.service';
import { FormsModule } from '@angular/forms';

declare let window: any;
declare let google: any;

@Component({
  selector: 'app-template-container',
  templateUrl: './mytemplate.component.html',
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyTemplateComponent implements OnInit, OnDestroy {

  // viewchild to put our generated templates in
  @ViewChild('vc', {read: ViewContainerRef}) _container: ViewContainerRef;
  templateComponentRef: any;

  currentItinerary: any;
  currentTemplate: any;
  currentStyle: any;
  exclusiveId: any;


  constructor(
    private _compiler: Compiler,
    private _injector: Injector,
    private _ngModuleRef: NgModuleRef<any>,
    private _activatedRoute: ActivatedRoute,
    //private pubnub: PubNubAngular,
    private http: HttpClient
  ) {

    this.exclusiveId = this._activatedRoute.snapshot.queryParams.exclusiveid;
    if(window != null && window.vtb != null){
      window.vtb.addEventListener('vtbDataReceived', (data) => {
        if (data.detail.message && data.detail.message.zraJson != null) {
          this.http.get(`${data.detail.message.zraJson}`).toPromise().then((x: any) => {
            if (x.data != null && x.selectedTemplate.css != null && x.selectedTemplate.html != null) {
              this.currentItinerary = x.data;
              this.currentStyle = x.selectedTemplate.css;
              this.currentTemplate = x.selectedTemplate.html.replace(/(\r\n|\n|\r)/gm, '').replace(/<vtb-zra-hide>(.*?)<\/vtb-zra-hide>/g, '');
              this.fetchZraBlocks(x.selectedTemplate.html);
              this.renderTemplate();
            } else {
              alert('Data missing, please check your console');
            }
          });
        }
        
        if(data.detail != null && data.detail.message != null && data.detail.message.source === 'vtb'){
          if(data.detail.message.fileName != null) {
            this.http.get(`https://vtb-live-mode.s3.eu-west-1.amazonaws.com/${data.detail.message.fileName}`).toPromise().then((x:any) => {
              this.currentItinerary = x.data;
              this.currentStyle = x.css;
              this.currentTemplate = x.html;
              this.renderTemplate();
            })
          }
          
          if(data.detail.message.vtbObjectId != null && this.templateComponentRef != null && this.templateComponentRef.instance != null && this.templateComponentRef.instance.itinerary != null  && this.templateComponentRef.instance.itinerary.segments != null){
            for(let segment of this.templateComponentRef.instance.itinerary.segments){
              if(segment.vtbObjectId === data.detail.message.vtbObjectId){
                segment[data.detail.message.propertyName] = data.detail.message.newValue;
                break;
              }
              for(let element of segment.elements){
                if(element.vtbObjectId === data.detail.message.vtbObjectId){
                  element[data.detail.message.propertyName] = data.detail.message.newValue;
                  break;
                }
              }
            }
            //this.renderTemplate(); // as i cant get change detection to work, completely refresh the template for now
            this.templateComponentRef.instance.setItinerary(this.currentItinerary);
          }
        }
      });

      let event = new CustomEvent("ready", {});
      window.vtb.dispatchEvent(event);
    }
  }

  fetchZraBlocks(html)
  {
    let zraBlocks = {};
    let zraPrices = [];
    let zraImages = {};

    let matches = html.replace(/\r?\n|\r/g, '').replace(/<!--(.*?)-->/g, '').match(/<vtb-zra(\s[^>]*?|>)>?>(.*?)<\/vtb-zra>/g);
    if(matches) {
      matches.forEach(match => {
        let html = match.match(/<vtb-zra.*?>(.*?)<\/vtb-zra>/);
        let data = match.match(/data-zra-identifier="(.*?)"/);
        let clean = html[1].match(/^<div.*?>(.*?)<\/div>$/);

        zraBlocks[data[1]] = clean[1];
      });
    }

    let imageMatches = html.replace(/\r?\n|\r/g, '').replace(/<!--(.*?)-->/g, '').match(/<vtb-zra-image([^>]*?)>(.*?)<\/vtb-zra-image>/g);
    if(imageMatches) {
      imageMatches.forEach(match => {
        let html = match.match(/<vtb-zra-image.*?>(.*?)<\/vtb-zra-image>/);
        let data = match.match(/data-zra-identifier="(.*?)"/);
        let clean = html[1].match(/^<div.*?>(.*?)<\/div>$/);

        zraImages[data[1]] = clean[1];
      });
    }

    let priceMatches = html.replace(/\r?\n|\r/g, '').replace(/<!--(.*?)-->/g, '').match(/<vtb-zra-price(\s[^>]*?|>)>?>(.*?)<\/vtb-zra-price>/g);
    if(priceMatches) {
      priceMatches.forEach(match => {
        let html = match.match(/<vtb-zra-price.*?>(.*?)<\/vtb-zra-price>/);
        // console.log(html);
        let data = match.match(/data-zra-identifier="(.*?)"/);
        // console.log(data);
        // let clean = html[1].match(/^<div.*?>(.*?)<\/div>$/);
        zraPrices.push(data[1]); 
      });
    }

    window.zraBlocks = zraBlocks;
    window.zraImages = zraImages;
    window.zraPrices = zraPrices;

    return zraBlocks;
  }

  renderTemplate() {
    this.resetStyles(this.currentStyle);
    /*const TemplateComponent = Component({template: this.currentTemplate, selector: 'app-template' })(class {
      //constructor(public cdr: ChangeDetectorRef){}
    });

    const TmpModule = NgModule({declarations: [TemplateComponent], imports: [
      BrowserModule,
      VtbComponentsModule.forRoot(environment),
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDa6wr3FY1sGlEpAzL2riOeMVGxdUGmCjI'
      }),
      InViewportModule
    ]})(class {
    });*/

    const currentTemplate = this.currentTemplate;

    @Component({
      template: currentTemplate,
      selector: 'app-template',
      //changeDetection: ChangeDetectionStrategy.OnPush
    })
    class DynamicComponent {
      @ViewChildren(AgmMap) public maps;
      itinerary: any;
      location: string;
      hasValidToken: boolean;
      initForm = false;
      initLazyLoad = false;
      zraUpdateZraFieldHandler: any;
      dispatchAgentUpdateHandler: any;
      vtbUpdateFieldHandler: any;
      formCurrentParticipant:string = '';
      currentParticipant:string = '';

      constructor(private cdr: ChangeDetectorRef, private applicationRef: ApplicationRef, private pricesService:PricesService, private zone: NgZone) {
        this.zraUpdateZraFieldHandler = this.zraUpdateZraField.bind(this);
        window.vtb.addEventListener('vtbUpdateZraField', this.zraUpdateZraFieldHandler);
        this.dispatchAgentUpdateHandler = this.dispatchAgentUpdate.bind(this);
        window.addEventListener('finishedDistribution', this.dispatchAgentUpdateHandler);
        this.vtbUpdateFieldHandler = this.vtbUpdateField.bind(this);
        window.vtb.addEventListener('vtbUpdateField', this.vtbUpdateFieldHandler);
      }

      selectParticipant()
      {
        this.currentParticipant = this.formCurrentParticipant;
      }

      deselectParticipant()
      {
        this.currentParticipant = '';
      }

      zraUpdateZraField(data) {
        if(!this.itinerary.hasOwnProperty('overwrites'))
          this.itinerary.overwrites = {};

        this.itinerary.overwrites[data.detail.message.zraId] = data.detail.message.content;
        if(data.detail.message.zraId.match(/^vtbObject/))
          this.applyToObject(data.detail.message);
          
        this.cdr.detectChanges();

        var event = new CustomEvent("vtbAgentUpdate", {
          detail: {
            message: this.itinerary
          }
        });
        window.vtb.dispatchEvent(event);
      }

      applyToObject(data)
      {
        let found = false;
        this.itinerary.segments.forEach(segment => {
          if(found) return;

          if(segment.vtbObjectId == data.vtbObjectId) {
            if(!segment.hasOwnProperty('overwrites'))
              segment.overwrites = {};

            segment.overwrites[data.zraId] = data.content;
            found = true;
          } else {
            segment.elements.forEach(element => {
              if(element.vtbObject == data.vtbObject) {
                if(!element.hasOwnProperty('overwrites'))
                  element.overwrites = {};
  
                element.overwrites[data.zraId] = data.content;
              }
            });
          }
        })
      }

      dispatchAgentUpdate()
      {
        setTimeout(() => {
          var event = new CustomEvent("vtbAgentUpdate", {
            detail: {
              message: this.itinerary
            }
          });
          window.vtb.dispatchEvent(event);
        }, 1000);
      }

      vtbUpdateField(data)
      {
        let hasFound = false;
        let vtbObjectId = data.detail.message.vtbObjectId;
        this.itinerary.segments.forEach(segment => {
          if(hasFound) return;

          if (segment.vtbObjectId === vtbObjectId) {
            segment[data.detail.message.vtbField] = data.detail.message.content;
            segment = this.addChangedField(segment, data.detail.message.vtbField);
            hasFound = true;
            return;
          }
          segment.elements.forEach(element => {
            if (element.vtbObjectId === vtbObjectId) {
              element[data.detail.message.vtbField] = data.detail.message.content;
              element = this.addChangedField(element, data.detail.message.vtbField);
              hasFound = true;
              return;
            }
          });
        });

        if (!hasFound && this.itinerary.TSOrder && this.itinerary.TSOrder.texts) {
          if (this.itinerary.TSOrder.texts.vtbObjectId === vtbObjectId) {
            this.itinerary.TSOrder.texts[data.detail.message.vtbField] = data.detail.message.content;
            this.itinerary.TSOrder.texts = this.addChangedField(this.itinerary.TSOrder.texts, data.detail.message.vtbField);
            hasFound = true;
          }
        }

        if (!hasFound) {
          this.itinerary.extraFieldValues.forEach(extraFieldValue => {
            if(hasFound) return;
            extraFieldValue.fields.forEach(field => {
              if(hasFound) return;
              if(field.vtbObjectId === vtbObjectId) {
                field.value = data.detail.message.content;
                field.changedField = true;
                hasFound = true;
              }
            });
          });
        }

        this.cdr.detectChanges();

        var event = new CustomEvent("vtbAgentUpdate", {
          detail: {
            message: this.itinerary
          }
        });
        window.vtb.dispatchEvent(event);
      }

      addChangedField(object, key)
      {
        if(!object.changedFields) {
          object.changedFields = [];
        }

        if(object.changedFields.indexOf(key) === -1) {
          object.changedFields.push(key);
        }

        return object;
      }

      ngOnDestroy()
      {
        window.vtb.removeEventListener('vtbUpdateZraField', this.zraUpdateZraFieldHandler);
        window.removeEventListener('finishedDistribution', this.dispatchAgentUpdateHandler);
        window.vtb.removeEventListener('vtbUpdateField', this.vtbUpdateFieldHandler);
      }
      
      setItinerary(itinerary){
        this.itinerary = this.pricesService.init(itinerary);
        this.hasValidToken = true;
        this.location = document.location.href;
        this.cdr.detectChanges();
        this.applicationRef.tick();
        window.addEventListener('vtbChangePrice', (data:any) => {
          this.itinerary = this.pricesService.doObjectToggle(this.itinerary, data.detail.data, data.detail.optional);
        });
      }

      showForm()
        {
          if(!this.initForm) {
            this.initForm = true;
            this.cdr.detectChanges();
          }
        }

      trackByFn(index, item)
      {
        return index;
      }

      ngAfterViewInit()
      {
        var event = new CustomEvent('vtbInited');
        window.dispatchEvent(event);

        let eventFinished = new CustomEvent("finishedDistribution", {
          detail: {
          }
        });

        window.dispatchEvent(eventFinished);
        console.log('iets met lazy');
        this.zone.runOutsideAngular(() => {
          window.addEventListener('scroll', (event) => {
            if(!this.initLazyLoad) {
              let top = event.target['scrollingElement'].scrollTop;
              if(top > 400) {
                this.initLazyLoad = true;
                this.cdr.detectChanges();
              }
            }
          });
        });
      }

      changeParticipants(data)
      {
        this.itinerary = this.pricesService.changeParticipants(this.itinerary, data);
        this.cdr.detectChanges();
      }

      setObjectRequired(data) 
      {
        this.itinerary = this.pricesService.setObjectRequired(this.itinerary, data);
        this.cdr.detectChanges();
      }

      doObjectToggle(data)
      {
        this.itinerary = this.pricesService.doObjectToggle(this.itinerary, data, null);
        this.cdr.detectChanges();
      }

      resizeMaps()
      {
        this.maps.forEach(map => {
          map.triggerResize();
            map._mapsWrapper._map.then(node => {
              if(node.getZoom() == 0) {
                node.setZoom(4);
              } else {
                map.triggerResize();
              }
            });
        });
      }


      setRequired(data)
      {
        this.itinerary = this.pricesService.setRequired(this.itinerary, data);

        this.cdr.detectChanges();
      }

      doToggle(data)
      {
        this.itinerary = this.pricesService.doToggle(this.itinerary, data);
        this.cdr.detectChanges();
      }

      includeElement(data)
      {
        this.itinerary = this.pricesService.includeElement(this.itinerary, data);
        this.cdr.detectChanges();
      }

      excludeElement(data)
      {
        this.itinerary = this.pricesService.excludeElement(this.itinerary, data);
        this.cdr.detectChanges();
      }

      doObjectToggleSingle(data, optional)
      {
        this.itinerary = this.pricesService.doObjectToggleSingle(this.itinerary, data);
        this.cdr.detectChanges();
      }
    }
    @NgModule({
      imports: [
        FormsModule,
        BrowserModule,
        VtbComponentsModule.forRoot(environment),
        AgmCoreModule.forRoot({
          apiKey: 'AIzaSyDa6wr3FY1sGlEpAzL2riOeMVGxdUGmCjI'
        }),
        AgmDirectionModule,
        InViewportModule
      ],
      declarations: [DynamicComponent],
    }) class TmpModule { }

    this._compiler.compileModuleAndAllComponentsAsync(TmpModule)
    .then((factories) => {
      const templateComponentFactory = factories.componentFactories.find(x => x.selector === 'app-template');
      this.templateComponentRef = templateComponentFactory.create(this._injector, [], null, this._ngModuleRef);

      // assign the message itinerary to the model
      this.templateComponentRef.instance.setItinerary(this.currentItinerary);

      // redraw
      this._container.clear();
      this._container.insert(this.templateComponentRef.hostView);

      let scrollY = localStorage.getItem('livePreviewScrollY');
      if(window.location.href.indexOf('load=open') > -1 && scrollY) {
        window.scrollTo(0, scrollY);
      } else {
        localStorage.removeItem('livePreviewScrollY');
      }
    });
  }

  resetStyles(css) {
    const elements = document.querySelectorAll('style[type="text/css"]');
    for(let i = 0 ; i < elements.length ; i++){
      if(elements[i].innerHTML.match(/flatpickr/)) continue;

      elements[i].parentNode.removeChild(elements[i]);
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    let style: any = document.createElement('style');
    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
  }

  ngOnDestroy() {
    if (this.templateComponentRef) {
      this.templateComponentRef.destroy();
    }
  }

  ngOnInit() {}
}
